import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { spacing } from '@mui/system';
import {
  Alert as MuiAlert,
  TextField,
  Grid,
  Box,
  styled,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FieldTitle from '../../components/FieldTitle';
import { Formik, FormikHelpers } from 'formik';
import { UserRoles } from '../../types/users';
import { mediaQuery } from '../../theme';
import { useLogin } from '../../api/auth';
import * as Yup from 'yup';
import { authValidationMessage } from '../../configs/responseMessage';
import { storeToken, setUserDetail } from '../../api/cacheStorage';
import { setupAxiosToken } from '../../util/apiService';
import packageInfo from '../../../package.json';

interface AuthValues {
  email: string;
  password: string;
  submit: boolean;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .email(authValidationMessage.EMAIL_INVALID)
    .max(255)
    .required(authValidationMessage.EMAIL_REQUIRED),
  password: Yup.string()
    .max(255)
    .required(authValidationMessage.PASSWORD_REQUIRED),
});

const LoginButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.primaryBlue,
  color: theme.white,
  borderRadius: '10px',
  fontWeight: '700',
  textTransform: 'none',
}));

const StyledContainer = styled('div')(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.primaryBlue,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: '30px',
  paddingTop: '50px',
  paddingBottom: '50px',
  borderRadius: 10,
  backgroundColor: theme.white,
  ...mediaQuery('minWidth', ['200px', '300px', '400px']),
}));

const VersionBlock = styled('div')(({ theme }) => ({
  color: theme.white,
  marginTop: '10px',
}));

const StyledTitle = styled(Typography)({
  textAlign: 'center',
});

const Alert = styled(MuiAlert)(spacing);

const LoginPage: FC = () => {
  const navigate = useNavigate();

  const [{ loading }, executePut] = useLogin();

  const onSubmit = async (
    values: AuthValues,
    { setErrors, setStatus }: FormikHelpers<AuthValues>,
  ) => {
    try {
      const {
        data: { access_token: accessToken, role, name, email, id },
      } = await executePut({
        data: {
          email: values.email.toLocaleLowerCase(),
          password: values.password,
        },
      });

      storeToken(accessToken);
      setUserDetail(JSON.stringify({ email, name, role, id }));
      setupAxiosToken(accessToken);
      if (role === UserRoles.PlatformAdmin) {
        navigate('/admin');
      } else {
        navigate('/landing');
      }
      location.reload();
    } catch (error) {
      setStatus({ success: false });
      setErrors({
        submit: authValidationMessage.EMAIL_PASSWORD_INCORRECT,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: false,
      }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        values,
        handleBlur,
        handleChange,
        touched,
        errors,
      }) => (
        <form onSubmit={handleSubmit}>
          <StyledContainer>
            <StyledBox boxShadow={3}>
              <Grid>
                <StyledTitle variant="h5" gutterBottom>
                  Welcome
                </StyledTitle>
                <StyledTitle variant="subtitle1" gutterBottom>
                  Sign into your Account
                </StyledTitle>
                {errors.submit && (
                  <Alert mt={2} mb={3} severity="error">
                    {errors.submit}
                  </Alert>
                )}
                <FieldTitle>Email</FieldTitle>
                <TextField
                  name="email"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid mt={2}>
                <FieldTitle>Password</FieldTitle>
                <TextField
                  name="password"
                  variant="outlined"
                  fullWidth
                  size="small"
                  type="password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid mt={5}>
                <LoginButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  size="large"
                  disabled={loading}
                  loading={loading}
                >
                  Login
                </LoginButton>
              </Grid>
            </StyledBox>
            {packageInfo?.version && (
              <VersionBlock>Version: {packageInfo?.version}</VersionBlock>
            )}
          </StyledContainer>
        </form>
      )}
    </Formik>
  );
};

export default LoginPage;
