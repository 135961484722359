import { useAxios } from '../util/apiService';

export interface PathwayIdsResponse {
  RTT_PATHWAY_ID: string;
}

interface CommentCreatedBy {
  id: number;
  name: string;
  email: string;
}

export interface CommentHistoryResponse {
  id: number;
  comment: string;
  createdAt: string;
  createdBy: CommentCreatedBy;
  dataset: null;
  RTT_PATHWAY_ID: string;
  updatedAt: string;
}

export interface CommentBody {
  RTT_PATHWAY_ID: string;
  comment: string;
}

export interface AddCommentResponse {
  createdByName: string;
  comment: string;
  RTT_PATHWAY_ID: string;
  dataset: null;
  id: number;
  createdAt: string;
  updatedAt: string;
}

export interface CommentHistory {
  history: CommentHistoryResponse[];
  count: number;
}

export const usePathwaySearch = () =>
  useAxios<PathwayIdsResponse[]>(
    {
      url: `patients/pathwayId/search`,
      method: 'GET',
    },
    { manual: true },
  );

export const useGetPatientDetail = () =>
  useAxios<PathwayIdsResponse[]>(
    {
      url: `patients/`,
      method: 'GET',
    },
    { manual: true },
  );

export const useGetCommentHistory = () =>
  useAxios<CommentHistory>(
    { url: `by-patient/`, method: 'GET' },
    { manual: true },
  );

export const useAddComment = () =>
  useAxios<AddCommentResponse>(
    {
      url: `patients/add-comment`,
      method: 'POST',
    },
    { manual: true },
  );

export const useUpdateComment = (commentId: number | undefined) =>
  useAxios<AddCommentResponse>(
    {
      url: `history/${commentId}`,
      method: 'PUT',
    },
    { manual: true },
  );

export const useDeleteComment = (commentId: number | undefined) =>
  useAxios<AddCommentResponse>(
    {
      url: `history/${commentId}`,
      method: 'DELETE',
    },
    { manual: true },
  );
