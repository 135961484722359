import { Nullable } from '../types/types';
const TOKEN = 'TOKEN';
const USER_DETAIL = 'USER_DETAIL';

export const storeToken = (token: string) => {
  localStorage.setItem(TOKEN, token);
};

export const getToken = (): Nullable<string> => {
  return localStorage.getItem(TOKEN) || null;
};

export const setUserDetail = (user: string) => {
  localStorage.setItem(USER_DETAIL, user);
};

export const getUser = () => {
  const user = localStorage.getItem(USER_DETAIL);
  return user && JSON.parse(user);
};

export const logOut = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(USER_DETAIL);
};
