import React, { FC } from 'react';
import UsersTable from './UsersTable';
import Typography from '@mui/material/Typography';

const AdminPage: FC = () => {
  return (
    <div style={{ marginTop: '50px' }}>
      <Typography variant="h4" gutterBottom>
        User Management
      </Typography>
      <UsersTable />
    </div>
  );
};

export default AdminPage;
