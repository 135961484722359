import React, { FC, useState } from 'react';
import Dropzone from 'react-dropzone';
import { styled, Typography } from '@mui/material';
import { mediaQuery } from '../../../theme';

const DropzoneStyle = styled('div')(() => ({
  display: 'flex',
  position: 'relative',
  marginBottom: '50px',
  textAlign: 'center',
  ...mediaQuery('height', ['250px', '320px']),
  ...mediaQuery('width', ['35%', '100%']),
}));

const DropzoneLabel = styled('label')(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: '2px',
  borderRadius: '1rem',
  borderStyle: 'dashed',
  borderColor: theme.primaryBlue,
  backgroundColor: theme.lightGray,
  fontSize: 22,
  fontFamily: 'Arial',
}));

const DropzoneInput = styled('input')(() => ({
  height: '100%',
  width: '100%',
}));

const StyledLabel = styled(Typography)({
  ...mediaQuery('fontSize', [15, 20]),
});

const DropZone: FC = () => {
  const [files, setFiles] = useState<File[]>([]);
  const handleDrop = (acceptedFiles: File[]) => {
    setFiles((files) => files.concat(...acceptedFiles));
  };

  return (
    <>
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <DropzoneStyle {...getRootProps()}>
            <DropzoneInput {...getInputProps()} />
            <DropzoneLabel>
              Drag & drop some files here, or click to select files
            </DropzoneLabel>
          </DropzoneStyle>
        )}
      </Dropzone>
      <StyledLabel>
        Files:
        {files.map((file) => (
          <li key={file.name}>{file.name}</li>
        ))}
      </StyledLabel>
    </>
  );
};

export default DropZone;
