import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import AppBar from './AppBar';
import { Container } from '@mui/material';

const Layout: FC = () => (
  <>
    <AppBar />
    <Container maxWidth="xl">
      <Outlet />
    </Container>
  </>
);

export default Layout;
