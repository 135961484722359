import React, { FC } from 'react';

const SettingsPage: FC = () => {
  return (
    <div>
      <h1>Settings Page</h1>
    </div>
  );
};

export default SettingsPage;
