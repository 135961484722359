import { useAxios } from '../util/apiService';

export interface GraphResponse {
  name: string;
  validations: number;
}

export const useGetSevenDays = () =>
  useAxios<GraphResponse[]>(
    {
      url: 'history/summary/by-seven-days',
      method: 'GET',
    },
    { manual: true },
  );

export const useGetSixMonths = () =>
  useAxios<GraphResponse[]>(
    {
      url: 'history/summary/by-six-months',
      method: 'GET',
    },
    { manual: true },
  );
