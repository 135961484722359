/* eslint-disable @typescript-eslint/no-empty-interface */
import App from './App';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import theme, { ThemeType } from './theme';
import { SnackbarProvider } from 'notistack';

declare module '@mui/material/styles' {
  interface Theme extends ThemeType {}
  interface ThemeOptions extends ThemeType {}
}
const themeSource = createTheme(theme);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={themeSource}>
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={4000}
        style={{
          fontSize: 22,
          minWidth: '50vw',
          justifyContent: 'center',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
