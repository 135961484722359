import { useAxios } from '../util/apiService';

interface LoginResponse {
  email: string;
  name: string;
  id: number;
  access_token: string;
  role: string;
}

interface LoginBody {
  email: string;
  password: string;
}

export const useLogin = () =>
  useAxios<LoginResponse, LoginBody>(
    {
      url: 'auth/login',
      method: 'POST',
    },
    { manual: true },
  );
