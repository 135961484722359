import React, { FC } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import FieldTitle from '../../../components/FieldTitle';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { UserResponse, resetPassword } from '../../../api/users';
import { useSnackbar } from 'notistack';
import { getApiError } from '../../../util/apiService';

interface ResetPasswordModalProps {
  data: UserResponse;
  open: boolean;
  onClose: () => void;
}

const schema = Yup.object().shape({
  newPassword: Yup.string().max(35).required('Password is required'),
  confirmPassword: Yup.string()
    .when('newPassword', {
      is: (val: string) => !!(val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        'Both password need to be the same',
      ),
    })
    .required('Required'),
});

const ResetPasswordModal: FC<ResetPasswordModalProps> = ({
  open,
  onClose,
  data,
}) => {
  const [{}, executeResetPassword] = resetPassword(data?.id);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    onClose();
  };
  interface Values {
    email: string;
    newPassword: string;
    confirmPassword: string;
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>Reset Account Password</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            email: data?.email,
            newPassword: '',
            confirmPassword: '',
          }}
          validationSchema={schema}
          onSubmit={async (
            values: Values,
            { resetForm }: FormikHelpers<Values>,
          ) => {
            try {
              const { data } = await executeResetPassword({
                data: {
                  newPassword: values.newPassword,
                  confirmPassword: values.confirmPassword,
                },
              });
              if (!data) {
                enqueueSnackbar('Something went wrong.', {
                  variant: 'error',
                });
              }
              enqueueSnackbar('Password is successfully changed', {
                variant: 'success',
              });
              handleClose();
              resetForm();
            } catch (error) {
              const errorMessage = getApiError(error);
              enqueueSnackbar(errorMessage, {
                variant: 'error',
              });
            }
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid
                container
                spacing={3}
                direction="column"
                sx={{ minWidth: '600px' }}
              >
                <Grid item xs={12}>
                  <FieldTitle>Email</FieldTitle>
                  <TextField
                    required
                    id="email"
                    label="Email"
                    size="small"
                    fullWidth
                    type="email"
                    onChange={handleChange}
                    disabled
                    value={data?.email}
                    onBlur={handleBlur}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldTitle>New password</FieldTitle>
                  <TextField
                    required
                    id="newPassword"
                    label="New password"
                    size="small"
                    fullWidth
                    type="password"
                    onChange={handleChange}
                    value={values.newPassword}
                    onBlur={handleBlur}
                    error={Boolean(touched.newPassword && errors.newPassword)}
                    helperText={touched.newPassword && errors.newPassword}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldTitle>Confirm new password</FieldTitle>
                  <TextField
                    required
                    id="confirmPassword"
                    label="Confirm new password"
                    size="small"
                    fullWidth
                    type="password"
                    onChange={handleChange}
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword,
                    )}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                  />
                </Grid>
              </Grid>

              <DialogActions>
                <Button type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit"> Change </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPasswordModal;
