import { useAxios } from '../util/apiService';

export interface SpecialtySummary {
  specialty: string;
  currentMonth: number;
  lastMonth: number;
}

export const getSpecialtySummary = () =>
  useAxios<SpecialtySummary[]>(
    {
      url: `history/summary/by-specialty`,
      method: 'GET',
    },
    { manual: true },
  );
