import React, { FC, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
} from '@mui/material';
import CommentText from '../../components/CommentText';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`;

const CloseButton: FC<{
  children?: React.ReactNode;
  onClickClose: Dispatch<SetStateAction<boolean>>;
}> = ({ children, onClickClose }) => (
  <Button
    variant="contained"
    sx={{
      backgroundColor: theme.primaryBlue,
      color: theme.white,
      marginLeft: '10px',
    }}
    onClick={() => {
      onClickClose(false);
    }}
  >
    {children}
  </Button>
);

const CommentFullViewModal: FC<{
  commentFullViewDialog: boolean;
  toggleCommentFullViewDialog: Dispatch<SetStateAction<boolean>>;
  comment: string;
}> = ({ commentFullViewDialog, toggleCommentFullViewDialog, comment }) => (
  <>
    <Dialog
      open={commentFullViewDialog}
      onClose={() => toggleCommentFullViewDialog(false)}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">Comment</DialogTitle>
      <DialogContent>
        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            <CommentText disabled value={comment} name="comment" />
            <ButtonContainer>
              <CloseButton onClickClose={toggleCommentFullViewDialog}>
                Close
              </CloseButton>
            </ButtonContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  </>
);

export default CommentFullViewModal;
