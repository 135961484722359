import React, { FC } from 'react';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import SpecialtySummaryTable from './SpecialtySummaryTable';
import BarChartView from './BarChartView';

const StyledTitle = styled(Typography)({
  textAlign: 'center',
  marginTop: 30,
  marginBottom: 40,
});

const LandingPage: FC = () => {
  return (
    <>
      <BarChartView />

      <StyledTitle variant="h4" gutterBottom fontWeight="bold">
        Validation Summary by Specialty
      </StyledTitle>

      <SpecialtySummaryTable />
    </>
  );
};

export default LandingPage;
