import { configure } from 'axios-hooks';
import Axios from 'axios';

const axios = Axios.create({
  baseURL: 'http://35.176.85.20/api',
});

export const configureAxios = () => {
  configure({ axios });
};
