import React, { FC, useState } from 'react';
import { Typography, styled, Button } from '@mui/material';
import FilesTable from './FilesTable';
import FileUploadModal from './FileUploadModal';
import { mediaQuery } from '../../../theme';

const UploadButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.primaryBlue,
  color: theme.white,
  marginBottom: 15,
  '&:hover': { backgroundColor: theme.gray },
  ...mediaQuery('fontSize', [13, 18]),
}));

const UploadView: FC = () => {
  const [openFileUpload, setOpenFileUpload] = useState(false);
  const onClose = () => {
    setOpenFileUpload(false);
  };

  return (
    <div style={{ marginTop: '50px' }}>
      <Typography variant="h4" gutterBottom>
        File Upload
      </Typography>
      <UploadButton onClick={() => setOpenFileUpload(true)}>
        Upload
      </UploadButton>
      <FilesTable />
      <FileUploadModal openFileUpload={openFileUpload} onClose={onClose} />
    </div>
  );
};

export default UploadView;
