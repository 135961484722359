const authValidationMessage = {
  EMAIL_INVALID: 'Must be a valid email',
  EMAIL_REQUIRED: 'Email is required',
  PASSWORD_REQUIRED: 'Password is required',
  EMAIL_PASSWORD_INCORRECT: 'Invalid email or password.',
};

const errorMessage = {
  SOMETHING_WRONG: 'Something went wrong',
};

export { authValidationMessage, errorMessage };
