import React, { FC, Dispatch, SetStateAction } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Button,
  styled,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import FieldTitle from '../../components/FieldTitle';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CommentText from '../../components/CommentText';
import { formatDateOnly, formatTimeOnly } from '../../util/helpers';
import {
  CommentHistoryResponse,
  useDeleteComment,
  useUpdateComment,
} from '../../api/patients';
import { Stack } from '@mui/system';

interface CommentUpdateProp {
  comment: string;
}

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`;

const CommentEditModal: FC<{
  data: CommentHistoryResponse | null;
  onEditSuccess: () => void;
  onDeleteSuccess: () => void;
  commentEditDialog: boolean;
  toggleCommentEditDialog: Dispatch<SetStateAction<boolean>>;
}> = ({
  commentEditDialog,
  toggleCommentEditDialog,
  data,
  onEditSuccess,
  onDeleteSuccess,
}) => {
  const [{}, execute] = useUpdateComment(data?.id);
  const [{}, executeDelete] = useDeleteComment(data?.id);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (values: CommentUpdateProp) => {
    try {
      const { data } = await execute({
        data: values,
      });
      if (!data) {
        enqueueSnackbar('Something went wrong.', {
          variant: 'error',
        });
      }
      enqueueSnackbar('Comment is successfully updated', {
        variant: 'success',
      });
      onEditSuccess();
    } catch (error) {
      enqueueSnackbar('Something went wrong.', {
        variant: 'error',
      });
    }
  };

  const deleteComment = async () => {
    try {
      const { data } = await executeDelete();
      if (!data) {
        enqueueSnackbar('Something went wrong.', {
          variant: 'error',
        });
      }
      enqueueSnackbar('Comment is successfully deleted', {
        variant: 'success',
      });
      onDeleteSuccess();
    } catch (error) {
      enqueueSnackbar('Something went wrong.', {
        variant: 'error',
      });
    }
  };

  const schema = Yup.object().shape({
    comment: Yup.string()
      .required('Comment is required')
      .max(8000, 'Character limit exceed'),
  });

  return (
    <Dialog
      open={commentEditDialog}
      onClose={() => toggleCommentEditDialog(false)}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">Comment View</DialogTitle>
      <DialogContent>
        {data && (
          <Formik
            initialValues={{ comment: data?.comment }}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              handleBlur,
              touched,
              errors,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FieldTitle>Date</FieldTitle>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={formatDateOnly(data.createdAt)}
                      disabled
                      size="small"
                      InputProps={{
                        style: {
                          fontSize: 24,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FieldTitle>Time</FieldTitle>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={formatTimeOnly(data.createdAt)}
                      disabled
                      size="small"
                      InputProps={{
                        style: {
                          fontSize: 24,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6} mt={1}>
                    <FieldTitle>User</FieldTitle>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={data.createdBy.email}
                      disabled
                      size="small"
                      InputProps={{
                        style: {
                          fontSize: 24,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} mt={1}>
                    <FieldTitle>Data set</FieldTitle>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value="YTD"
                      disabled
                      size="small"
                      InputProps={{
                        style: {
                          fontSize: 24,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={12} mt={1}>
                    <FieldTitle>Comment</FieldTitle>
                    <CommentText
                      value={values.comment}
                      name="comment"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.comment && errors.comment)}
                      helperText={touched.comment && errors.comment}
                    />
                    <ButtonContainer>
                      <Stack direction="row" gap={2}>
                        <Button
                          color="error"
                          variant="contained"
                          onClick={deleteComment}
                        >
                          Delete
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          Update
                        </Button>
                      </Stack>
                    </ButtonContainer>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CommentEditModal;
