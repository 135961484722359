import React, { FC, useState, useEffect } from 'react';
import { Grid, styled, Typography } from '@mui/material';

import ValidationSummeryChart from './ValidationSummeryChart';
import {
  useGetSevenDays,
  GraphResponse,
  useGetSixMonths,
} from '../../api/graph';

const StyledFieldTitle = styled(Typography)({
  textAlign: 'center',
  fontSize: 30,
});

const BarChartView: FC = () => {
  const [days, setDays] = useState<GraphResponse[]>([]);
  const [months, setMonths] = useState<GraphResponse[]>([]);
  const [{}, executeSevenDays] = useGetSevenDays();
  const [{}, executeSixMonths] = useGetSixMonths();

  const fetchSevenDays = async () => {
    try {
      const { data } = await executeSevenDays();
      if (data) {
        const sortedData = data.reverse();
        setDays(sortedData);
      }
    } catch (error) {}
  };

  const fetchSixMonths = async () => {
    try {
      const { data } = await executeSixMonths();
      if (data) {
        const sortedData = data.reverse();
        setMonths(sortedData);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchSevenDays();
    fetchSixMonths();
  }, []);

  return (
    <Grid container spacing={25} direction="row">
      <Grid item xs={12} md={6} mt={5}>
        <StyledFieldTitle variant="subtitle1">
          Seven days validation summary
        </StyledFieldTitle>
        <ValidationSummeryChart data={days} angle={-15} />
      </Grid>

      <Grid item xs={12} md={6} mt={5}>
        <StyledFieldTitle variant="subtitle1">
          Six months validation summary
        </StyledFieldTitle>
        <ValidationSummeryChart data={months} />
      </Grid>
    </Grid>
  );
};
export default BarChartView;
