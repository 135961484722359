import { useAxios } from '../util/apiService';
import { UserRegisterResponse, UserRegisterBody } from '../types/users';

export interface UserResponse {
  email: string;
  name: string;
  id: number;
  status: string;
  role: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface UserGroups {
  id: number;
  name: string;
  status: boolean;
}

export interface Users {
  users: UserResponse[];
  count: number;
}

export const getUsers = (skipRows: number, takeRows: number) =>
  useAxios<Users>(
    {
      url: `/users/${skipRows}/${takeRows}`,
      method: 'GET',
    },
    { manual: true },
  );

export const deleteUser = (userId: number | undefined) =>
  useAxios(
    {
      url: `/users/deleteuser/${userId}`,
      method: 'DELETE',
    },
    { manual: true },
  );

export const toggleUserEnability = (
  userId: number | undefined,
  status: string,
) =>
  useAxios(
    {
      url: `/users/enability/${userId}/${status}`,
      method: 'PUT',
    },
    { manual: true },
  );

export const useRegister = () =>
  useAxios<UserRegisterResponse, UserRegisterBody>(
    {
      url: 'users',
      method: 'POST',
    },

    { manual: true },
  );

export const useGetUserRoles = () =>
  useAxios<UserGroups[]>(
    {
      url: 'usergroups',
      method: 'GET',
    },
    { manual: true },
  );

export const updateUser = (userId: number | undefined) =>
  useAxios(
    {
      url: `users/updateuser/${userId}`,
      method: 'PUT',
    },
    { manual: true },
  );

export const resetPassword = (userId: number | undefined) =>
  useAxios(
    {
      url: `/users/reset-password/${userId}`,
      method: 'PUT',
    },
    { manual: true },
  );
