import React, { ChangeEvent, FC, useEffect, useState, MouseEvent } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Pagination,
  MenuItem,
  styled,
  IconButton,
  Menu,
} from '@mui/material';
import theme, { mediaQuery } from '../../../theme';
import { useConfirm } from 'material-ui-confirm';
import {
  deleteUser,
  getUsers,
  toggleUserEnability,
  UserResponse,
} from '../../../api/users';
import { useSnackbar } from 'notistack';
import ResetPasswordModal from './ResetPasswordModal';
import UserEditFormModal from './UserEditFormModal';
import { useUsers } from '../../../context/UserContext';
import { dashRemover } from '../../../util/helpers';
import UserFormModal from './UserFormModal';
import { ROWS_PER_PAGE } from '../../../configs/constants';
import SettingsIcon from '@mui/icons-material/Settings';

const TableHeaderCell = styled(TableCell)(() => ({
  textAlign: 'center',
  ...mediaQuery('fontSize', [18, 28]),
}));

const Cell = styled(TableCell)(() => ({
  ...mediaQuery('fontSize', [15, 24]),
}));

const PaginationContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 20,
  marginBottom: 10,
});

const UsersTable: FC = () => {
  const { openUserModal, setOpenUserModal } = useUsers();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedUser, setSelectedUser] = useState<any>();
  const [pageCount, setPageCount] = useState(1);
  const [users, setUsers] = useState<UserResponse[]>();
  const [skippedRows, setSkippedRows] = useState<number>(0);
  const [takeRows] = useState<number>(ROWS_PER_PAGE);
  const [selectedPage, setSelectedPage] = useState(1);
  const confirm = useConfirm();
  const [{}, executegetUser] = getUsers(skippedRows, takeRows);
  const [{}, executeDeleteUser] = deleteUser(selectedUser?.id);
  const [{}, executeUserEnability] = toggleUserEnability(
    selectedUser?.id,
    selectedUser?.status == 'enabled' ? 'disabled' : 'enabled',
  );
  const { enqueueSnackbar } = useSnackbar();
  const [openResetPassword, setOpenResetPasswordModal] = useState(false);
  const [openUserEditModal, setOpenUseEditModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const getPageCount = (count: number) => {
    const noOfPages = Math.ceil(count / ROWS_PER_PAGE);
    setPageCount(noOfPages);
  };

  const getAllUsers = async () => {
    try {
      const {
        data: { users, count },
      } = await executegetUser();

      if (users) {
        setUsers(users);
        getPageCount(count);
      }
    } catch (error) {
      // enqueueSnackbar('Something went wrong.', {
      //   variant: 'error',
      // });
    }
  };
  useEffect(() => {
    getAllUsers();
  }, [skippedRows]);

  const handleDelete = () => {
    setAnchorEl(null);
    confirm({ description: 'Are you sure you want to delete this user?' }).then(
      async () => {
        try {
          await executeDeleteUser();
          enqueueSnackbar('User successfully deleted.', {
            variant: 'success',
          });

          if (users && users.length == 1) {
            const prevPage = selectedPage - 1;
            setSelectedPage(prevPage);
            const noOfSkipRows = (prevPage - 1) * ROWS_PER_PAGE;
            setSkippedRows(noOfSkipRows);
          } else {
            getAllUsers();
          }
        } catch (error) {
          enqueueSnackbar('Delete attempt failed.', {
            variant: 'error',
          });
        }
      },
    );
  };

  const handleUserEnanbility = (enable: string, userId: number) => {
    setUsers(
      users?.map((user: UserResponse) => {
        if (user.id == userId) {
          executeUserEnability();
          if (enable == 'enabled') {
            return { ...user, status: 'disabled' };
          } else {
            return { ...user, status: 'enabled' };
          }
        } else {
          return { ...user };
        }
      }),
    );
  };
  const onCloseResetPassword = () => {
    setOpenResetPasswordModal(false);
  };

  const onClose = () => {
    getAllUsers();
    setOpenUseEditModal(false);
  };

  const handleNextPage = () => {
    if (users) {
      if (users?.length === ROWS_PER_PAGE) {
        const nextPage = selectedPage + 1;
        setSelectedPage(nextPage);
        const noOfSkipRows = (nextPage - 1) * ROWS_PER_PAGE;
        setSkippedRows(noOfSkipRows);
      }
    }
    getAllUsers();
  };

  const onCloseUserFormModal = () => {
    getAllUsers();
    setOpenUserModal(false);
  };

  const handleChangePage = (event: ChangeEvent<unknown>, newPage: number) => {
    setSelectedPage(newPage);
    const noOfSkipRows = (newPage - 1) * ROWS_PER_PAGE;
    setSkippedRows(noOfSkipRows);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEditModal = () => {
    setAnchorEl(null);
    setOpenUseEditModal(true);
  };
  const handleOpenResetPasswordModal = () => {
    setAnchorEl(null);
    setOpenResetPasswordModal(true);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Email</TableHeaderCell>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Role</TableHeaderCell>
            <TableHeaderCell />
            <TableHeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((user: UserResponse) => (
            <>
              <TableRow
                key={user.email}
                sx={{
                  '&:nth-child(odd)': { backgroundColor: theme.lightGray },
                }}
                onMouseOver={() => setSelectedUser(user)}
              >
                <Cell align="center" component="th" scope="row">
                  {user.email}
                </Cell>
                <Cell align="center">{user.name}</Cell>
                <Cell align="center">{dashRemover(user.role)}</Cell>

                <Cell align="center">
                  <Switch
                    onClick={() => handleUserEnanbility(user.status, user.id)}
                    checked={user?.status == 'enabled'}
                  />
                </Cell>
                <Cell align="center">
                  <IconButton onClick={handleClick}>
                    <SettingsIcon fontSize="inherit" />
                  </IconButton>
                </Cell>
              </TableRow>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
                <MenuItem onClick={handleOpenEditModal}>Edit</MenuItem>
                <MenuItem onClick={handleOpenResetPasswordModal}>
                  Reset Password
                </MenuItem>
              </Menu>
            </>
          ))}
        </TableBody>
      </Table>
      {pageCount > 1 && (
        <PaginationContainer>
          <Pagination
            count={pageCount}
            page={selectedPage}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </PaginationContainer>
      )}

      {openUserModal && (
        <UserFormModal
          open={openUserModal}
          onClose={onCloseUserFormModal}
          initialValues={null}
          handleNextPage={handleNextPage}
        />
      )}
      <UserEditFormModal
        open={openUserEditModal}
        onClose={onClose}
        data={selectedUser}
      />
      <ResetPasswordModal
        open={openResetPassword}
        onClose={onCloseResetPassword}
        data={selectedUser}
      />
    </TableContainer>
  );
};
export default UsersTable;
