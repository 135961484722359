import React, { FC } from 'react';
import {
  Container,
  TextField,
  Grid,
  Button,
  Typography,
  styled,
} from '@mui/material';
import FieldTitle from '../../components/FieldTitle';
import { Formik, FormikHelpers } from 'formik';
import { useAddComment } from '../../api/patients';
import { formatDateOnly } from '../../util/helpers';
import { UserRoles } from '../../types/users';
import { getUser } from '../../api/cacheStorage';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import CommentText from '../../components/CommentText';

interface Values {
  comment: string;
}

const schema = Yup.object().shape({
  comment: Yup.string()
    .max(8000, 'Character limit exceed')
    .required('Comment is required'),
});

const SubmitButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`;

const SectionTitle = styled(Typography)({
  fontSize: 30,
  fontWeight: 'bold',
  marginTop: 20,
});

const CommentTitle = styled(Typography)({
  fontSize: 24,
  fontWeight: 'bold',
  marginTop: 20,
});

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.primaryBlue,
  color: theme.white,
  fontSize: 18,
}));

const PatientDetailForm: FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  patientDetail: any;
  getCommentHistory: () => void;
}> = ({ patientDetail, getCommentHistory }) => {
  const [{}, execute] = useAddComment();
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmitComment = () => {
    enqueueSnackbar('Comment is successfully saved', {
      variant: 'success',
    });
  };

  const onSubmit = async (
    values: Values,
    { setSubmitting, resetForm }: FormikHelpers<Values>,
  ) => {
    const { data } = await execute({
      data: {
        ...values,
        RTT_PATHWAY_ID: patientDetail.RTT_PATHWAY_ID,
        specialty: patientDetail.SPECIALTY,
      },
    });

    if (data) {
      setTimeout(() => {
        setSubmitting(false);
        resetForm();
        getCommentHistory();
      }, 500);
      handleSubmitComment();
    }
  };

  if (!patientDetail) {
    return null;
  }

  const isReader = getUser().role === UserRoles.DataReader;

  return (
    <Formik
      initialValues={{
        comment: '',
      }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        handleBlur,
      }) => (
        <form onSubmit={handleSubmit}>
          <Container maxWidth="md">
            <SectionTitle gutterBottom>Patient Details</SectionTitle>

            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <FieldTitle>Local Patient ID</FieldTitle>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={patientDetail.LOCAL_PATIENT_ID}
                  disabled
                  size="small"
                  InputProps={{
                    style: {
                      fontSize: 24,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldTitle>Date of Birth</FieldTitle>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={formatDateOnly(patientDetail.PERSON_BIRTH_DATE)}
                  disabled
                  size="small"
                  InputProps={{
                    style: {
                      fontSize: 24,
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={6}>
              <Grid item xs={12} md={6} mt={1}>
                <FieldTitle>Patient Name</FieldTitle>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={patientDetail.PATIENT_NAME}
                  disabled
                  size="small"
                  InputProps={{
                    style: {
                      fontSize: 24,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} mt={1}>
                <FieldTitle>NHS No</FieldTitle>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={patientDetail.NHS_NUMBER}
                  disabled
                  size="small"
                  InputProps={{
                    style: {
                      fontSize: 24,
                    },
                  }}
                />
              </Grid>
            </Grid>

            <SectionTitle gutterBottom>RTT Pathway Details</SectionTitle>

            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <FieldTitle>Pathway ID</FieldTitle>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={patientDetail.RTT_PATHWAY_ID}
                  disabled
                  size="small"
                  InputProps={{
                    style: {
                      fontSize: 24,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldTitle>Treatment Function</FieldTitle>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={patientDetail.TREATMENT_FUNCTION}
                  disabled
                  size="small"
                  InputProps={{
                    style: {
                      fontSize: 24,
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={6}>
              <Grid item xs={12} md={6} mt={1}>
                <FieldTitle>Pathway Start Date</FieldTitle>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={formatDateOnly(patientDetail.CLOCK_START_DATE)}
                  disabled
                  size="small"
                  InputProps={{
                    style: {
                      fontSize: 24,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} mt={1}>
                <FieldTitle>Specialty</FieldTitle>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={patientDetail.SPECIALTY}
                  disabled
                  size="small"
                  InputProps={{
                    style: {
                      fontSize: 24,
                    },
                  }}
                />
              </Grid>
            </Grid>
            {!isReader && (
              <>
                <CommentTitle>Comment</CommentTitle>

                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <CommentText
                      name={'comment'}
                      value={values.comment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.comment && errors.comment)}
                      helperText={touched.comment && errors.comment}
                    />

                    <SubmitButtonContainer>
                      <SubmitButton
                        disabled={!values.comment}
                        type="submit"
                        variant="contained"
                      >
                        Submit comment
                      </SubmitButton>
                    </SubmitButtonContainer>
                  </Grid>
                </Grid>
              </>
            )}
          </Container>
          ;
        </form>
      )}
    </Formik>
  );
};

export default PatientDetailForm;
