import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import DropZone from './DropZone';
import Message from '../../../components/Message';

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 15px;
`;

const AddButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.primaryBlue,
  color: theme.white,
  marginLeft: 2,
  '&:hover': { backgroundColor: theme.gray },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.error,
  color: theme.white,
  marginRight: 10,
  '&:hover': { backgroundColor: theme.gray },
}));
interface Values {
  fileName: string;
  fileType: string;
}

const FileUploadModal: FC<{
  openFileUpload: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
}> = ({ openFileUpload, onClose }) => {
  const [alertView, setAlertView] = useState(false);

  const handleSubmitAdd = () => {
    setAlertView(true);
  };
  const handleClose = () => {
    onClose(false);
  };

  const onSubmit = (
    values: Values,
    { setSubmitting }: FormikHelpers<Values>,
  ) => {
    setTimeout(() => {
      setSubmitting(false);
    }, 500);
    handleSubmitAdd();
  };
  return (
    <Dialog open={openFileUpload} onClose={handleClose} maxWidth="md">
      <DialogTitle>Add a File</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            fileName: '',
            fileType: '',
          }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {alertView && (
                <Message
                  onClose={setAlertView}
                  message={'1 file updated'}
                  severity="success"
                />
              )}
              <Grid
                container
                spacing={3}
                direction="column"
                sx={{ minWidth: '800px' }}
              >
                <Grid item xs={12}>
                  <DropZone />
                </Grid>
              </Grid>
              <ButtonContainer>
                <CancelButton onClick={handleClose}>Cancel</CancelButton>
                <AddButton type="submit">Add</AddButton>
              </ButtonContainer>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default FileUploadModal;
