import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';

import { RTT_API_URL } from '../configs/constants';
import { logOut } from '../api/cacheStorage';

const rttAxios = axios.create({ baseURL: RTT_API_URL });
export const useAxios = makeUseAxios({
  axios: rttAxios,
});

rttAxios.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status) {
      const {
        response: { status },
      } = error;

      if (status === 401) {
        logOut();
        location.reload();
      }
    }
    return Promise.reject(error);
  },
);

export function getApiError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  defaultMessage?: string,
): string {
  if (!error || !error.response) {
    return 'Something went wrong';
  }

  if (error.response.data) {
    if (typeof error.response.data === 'string') {
      return error.response.data;
    }
    if (typeof error.response.data.message === 'string') {
      return error.response.data.message;
    }
    if (typeof error.response.data.error === 'string') {
      return error.response.data.error;
    }
  }

  return defaultMessage || 'Something went wrong';
}

export function setupAxiosToken(token: string | null): void {
  if (token) {
    rttAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}
