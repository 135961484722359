import moment from 'moment';

export const formatdate = (date: string) => {
  return moment(date).format('DD MMM, YYYY HH:mm');
};

export const formatDateOnly = (date: string) => {
  return moment(date).format('DD/MM/YYYY');
};

export const formatTimeOnly = (date: string) => {
  return moment(date).format('hh:mm a');
};

export const dashRemover = (stringValue: string) => {
  return stringValue
    .split('-')
    .map((word: string) => {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    })
    .join(' ');
};
