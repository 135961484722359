import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import PatientDetailForm from './PatientDetailsForm';
import CommentHistoryTable from './CommentHistoryTable';
import {
  CommentHistoryResponse,
  useGetCommentHistory,
  useGetPatientDetail,
} from '../../api/patients';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import { ROWS_PER_PAGE } from '../../configs/constants';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const PatientDetailPage: FC = () => {
  const [{ loading }, execute] = useGetPatientDetail();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [patientDetail, setPatientDetail] = useState<any>();
  const [history, setHistory] = useState<CommentHistoryResponse[]>([]);
  const [skippedRows, setSkippedRows] = useState<number>(0);
  const [takeRows] = useState<number>(ROWS_PER_PAGE);
  const [pageCount, setPageCount] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);

  const [{}, executeGetComments] = useGetCommentHistory();
  const { enqueueSnackbar } = useSnackbar();
  const rowCount = history?.length;

  const getPageCount = (count: number) => {
    const noOfPages = Math.ceil(count / ROWS_PER_PAGE);
    setPageCount(noOfPages);
  };

  const getCommentHistory = async () => {
    if (patientDetail?.RTT_PATHWAY_ID) {
      try {
        const {
          data: { history, count },
        } = await executeGetComments({
          url: `/history/by-patient/${patientDetail?.RTT_PATHWAY_ID}/${skippedRows}/${takeRows}`,
        });
        setHistory(history);
        getPageCount(count);
      } catch (error) {
        enqueueSnackbar('Something went wrong.', {
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    getCommentHistory();
  }, [patientDetail, skippedRows]);

  useEffect(() => {
    const { pathname } = window.location;
    const [, , pathwayId] = pathname.split('/');
    const fetchData = async () => {
      try {
        const { data } = await execute({
          url: `/patients/${pathwayId}`,
        });
        setPatientDetail(data);
      } catch (error) {}
    };
    fetchData();
  }, [window.location.pathname]);
  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '10px',
        }}
      >
        <CircularProgress />
      </Box>
    );
  const handleTablePage = (event: ChangeEvent<unknown>, newPage: number) => {
    setSelectedPage(newPage);
    const noOfSkipRows = (newPage - 1) * ROWS_PER_PAGE;
    setSkippedRows(noOfSkipRows);
  };

  const handleDeletePagination = () => {
    if (history && history.length == 1 && selectedPage > 1) {
      const prevPage = selectedPage - 1;
      setSelectedPage(prevPage);
      const noOfSkipRows = (prevPage - 1) * ROWS_PER_PAGE;
      setSkippedRows(noOfSkipRows);
    } else {
      getCommentHistory();
    }
  };

  return (
    <>
      <PatientDetailForm
        getCommentHistory={getCommentHistory}
        patientDetail={patientDetail}
      />
      <TitleContainer>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: 'bold', fontSize: 30 }}
        >
          Comments History
        </Typography>
      </TitleContainer>
      <CommentHistoryTable
        getCommentHistory={getCommentHistory}
        history={history}
        pageCount={pageCount}
        selectedPage={selectedPage}
        handleTablePage={handleTablePage}
        handleDeletePagination={handleDeletePagination}
        rowCount={rowCount}
      />
    </>
  );
};

export default PatientDetailPage;
