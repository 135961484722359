import NavigationRoutes from './NavigationRoutes';
import React, { FC } from 'react';
import { ConfirmProvider } from 'material-ui-confirm';
import { configureAxios } from './api/configureAxios';
import UserContextProvider from './context/UserContext';

configureAxios();
const App: FC = () => (
  <UserContextProvider>
    <div className="App">
      <ConfirmProvider>
        <NavigationRoutes />
      </ConfirmProvider>
    </div>
  </UserContextProvider>
);
export default App;
