import React, { FC, PropsWithChildren } from 'react';
import {
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
  styled,
} from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import theme, { mediaQuery } from '../../../theme';
import { useNavigate } from 'react-router-dom';

const DRAWER_WIDTH = 260;

const StyledToolbar = styled(Toolbar)({
  backgroundColor: theme.white,
  ...mediaQuery('height', ['250px', '120px']),
});

const StyledListItemText: FC<PropsWithChildren> = ({ children }) => (
  <ListItemText
    primary={children}
    primaryTypographyProps={{ fontSize: '20px' }}
  />
);

const SideBarDrawer: FC<{
  openDrawer: boolean;
  onCloseDrawer: (close: boolean) => void;
}> = ({ openDrawer, onCloseDrawer }) => {
  const navigate = useNavigate();

  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={openDrawer}
      onClose={() => onCloseDrawer(false)}
      sx={{
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          backgroundColor: theme.lightGray,
        },
      }}
    >
      <StyledToolbar />
      <Divider />
      <List>
        <ListItem key={'userManagement'} disablePadding>
          <ListItemButton onClick={() => navigate('/admin')}>
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <StyledListItemText>User Management</StyledListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem key={'fileUpload'} disablePadding>
          <ListItemButton onClick={() => navigate('/admin/file-upload')}>
            <ListItemIcon>
              <FileUploadIcon />
            </ListItemIcon>
            <StyledListItemText>File Upload</StyledListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default SideBarDrawer;
