import React, { FC, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from '@mui/material';
import theme, { mediaQuery } from '../../theme';
import { getSpecialtySummary } from '../../api/tableSummary';
import { SpecialtySummary } from '../../api/tableSummary';
// import { useSnackbar } from 'notistack';

const Cell = styled(TableCell)(() => ({
  ...mediaQuery('fontSize', [15, 24]),
}));

const TableHeaderCell = styled(TableCell)(() => ({
  textAlign: 'center',
  ...mediaQuery('fontSize', [18, 28]),
}));

const SpecialtySummaryTable: FC = () => {
  const [{}, executePut] = getSpecialtySummary();
  const [summaryData, setSummarayData] = useState<SpecialtySummary[]>([]);
  // const { enqueueSnackbar } = useSnackbar();

  const handleSummaryData = async () => {
    try {
      const { data } = await executePut();

      if (data) setSummarayData(data);
    } catch (error) {
      // enqueueSnackbar('Something went wrong.', {
      //   variant: 'error',
      // });
    }
  };

  useEffect(() => {
    handleSummaryData();
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650, paddingBottom: 30 }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableHeaderCell>Specialty</TableHeaderCell>
            <TableHeaderCell>This month</TableHeaderCell>
            <TableHeaderCell>Last month</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summaryData?.map(({ specialty, currentMonth, lastMonth }) => (
            <TableRow
              key={specialty}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '&:nth-child(odd)': { backgroundColor: theme.lightGray },
              }}
            >
              <Cell align="center" component="th" scope="row">
                {specialty}
              </Cell>
              <Cell align="center">{currentMonth ? currentMonth : 0}</Cell>
              <Cell align="center">{lastMonth ? lastMonth : 0}</Cell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SpecialtySummaryTable;
