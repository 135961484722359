export enum UserRoles {
  DataReader = 'rtt-data-reader',
  Validator = 'rtt-validator',
  RTTAdmin = 'rtt-admin',
  PlatformAdmin = 'platform-user',
}

export interface User {
  name: string;
  email: string;
  role: string;
}

export interface UserRegisterBody {
  //=== password autogenerated ===
  email: string;
  name: string;
  role: string;
  password: string;
}

export interface UserRegisterResponse {
  email: string;
  name: string;
  role: string;
  id: number;
}
