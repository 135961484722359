import React, {
  FC,
  useState,
  Dispatch,
  SetStateAction,
  ChangeEvent,
} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Pagination,
  styled,
} from '@mui/material';
import theme, { mediaQuery } from '../../theme';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CommentEditModal from './CommentEditModal';
import CommentFullViewModal from './CommentFullViewModal';
import { CommentHistoryResponse } from '../../api/patients';
import { formatdate } from '../../util/helpers';
import { UserRoles } from '../../types/users';
import { getUser } from '../../api/cacheStorage';

const PaginationContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const TableHeaderCell = styled(TableCell)(() => ({
  textAlign: 'center',
  ...mediaQuery('fontSize', [18, 28]),
}));

const Cell = styled(TableCell)(() => ({
  ...mediaQuery('fontSize', [15, 24]),
}));

const CommentTableCell: FC<{
  children?: React.ReactNode;
  handleOnPress: Dispatch<SetStateAction<boolean>>;
  setComment: Dispatch<SetStateAction<string>>;
  comment: string;
}> = ({ children, handleOnPress, setComment, comment }) => (
  <Cell
    align="center"
    sx={{
      '&.MuiTableCell-root:hover': {
        cursor: 'pointer',
      },
      maxWidth: '500px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'noWrap',
      textAlign: 'left',
    }}
    onClick={() => {
      handleOnPress(true);
      setComment(comment);
    }}
  >
    {children}
  </Cell>
);

const CommentHistoryTable: FC<{
  history: CommentHistoryResponse[];
  getCommentHistory: () => void;
  pageCount: number;
  selectedPage: number;
  handleTablePage: (event: ChangeEvent<unknown>, newPage: number) => void;
  handleDeletePagination: () => void;
  rowCount: number;
}> = ({
  history,
  getCommentHistory,
  pageCount,
  selectedPage,
  handleTablePage,
  handleDeletePagination,
  rowCount,
}) => {
  const [commentEditDialog, toggleCommentEditDialog] = useState(false);
  const [commentFullViewDialog, toggleCommentFullViewDialog] = useState(false);
  const [historyData, setHistoryData] = useState<CommentHistoryResponse | null>(
    null,
  );

  const handleEditSuccess = () => {
    getCommentHistory();
    toggleCommentEditDialog(false);
  };

  const handleDeleteSuccess = () => {
    if (rowCount == 1) {
      handleDeletePagination();
    } else {
      getCommentHistory();
    }
    toggleCommentEditDialog(false);
  };
  const userRole = getUser().role;
  const isReader = userRole === UserRoles.DataReader;
  const isAdmin = userRole === UserRoles.RTTAdmin;
  const userId = getUser().id;
  const [comment, setComment] = useState('');

  return (
    <TableContainer component={Paper} sx={{ marginBottom: 5 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Event Date</TableHeaderCell>
            <TableHeaderCell>User</TableHeaderCell>
            <TableHeaderCell>Type Name</TableHeaderCell>
            <TableHeaderCell>Comment</TableHeaderCell>
            {!isReader && <TableHeaderCell />}
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((historyItem: CommentHistoryResponse) => {
            const { id, comment, createdAt, createdBy } = historyItem;
            const { email, id: createdById, name } = createdBy;

            return (
              <TableRow
                key={id}
                sx={{
                  '&:nth-child(odd)': { backgroundColor: theme.lightGray },
                }}
              >
                <Cell align="center" component="th" scope="row">
                  {formatdate(createdAt)}
                </Cell>
                <Cell align="center">{name || email}</Cell>
                <Cell align="center">Pathway Validated</Cell>
                <CommentTableCell
                  handleOnPress={toggleCommentFullViewDialog}
                  setComment={setComment}
                  comment={comment}
                >
                  {comment}
                </CommentTableCell>
                <Cell align="center">
                  {!isReader && (userId === createdById || isAdmin) && (
                    <IconButton
                      onClick={() => {
                        setHistoryData(historyItem);
                        toggleCommentEditDialog(true);
                      }}
                    >
                      <ModeEditIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </Cell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {pageCount > 1 && (
        <PaginationContainer>
          <Pagination
            count={pageCount}
            page={selectedPage}
            onChange={handleTablePage}
            showFirstButton
            showLastButton
          />
        </PaginationContainer>
      )}

      <CommentEditModal
        data={historyData}
        commentEditDialog={commentEditDialog}
        onEditSuccess={handleEditSuccess}
        onDeleteSuccess={handleDeleteSuccess}
        toggleCommentEditDialog={toggleCommentEditDialog}
      />
      <CommentFullViewModal
        commentFullViewDialog={commentFullViewDialog}
        toggleCommentFullViewDialog={toggleCommentFullViewDialog}
        comment={comment}
      />
    </TableContainer>
  );
};

export default CommentHistoryTable;
