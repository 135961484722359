import React, {
  FC,
  ChangeEventHandler,
  FocusEventHandler,
  ReactNode,
} from 'react';
import { TextField } from '@mui/material';

interface CommentTextProp {
  disabled?: boolean;
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  name: string;
  error?: boolean;
  helperText?: ReactNode;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

const CommentText: FC<CommentTextProp> = ({
  disabled,
  value,
  onChange,
  name,
  error,
  helperText,
  onBlur,
}) => (
  <TextField
    name={name}
    variant="outlined"
    placeholder="Max letters 8000 characters"
    fullWidth
    multiline
    value={value}
    disabled={disabled}
    error={error}
    helperText={helperText}
    onBlur={onBlur}
    onChange={onChange}
    rows="5"
    InputProps={{
      style: {
        height: 200,
        resize: 'none',
        width: '100%',
        fontFamily: 'Arial',
        fontSize: 24,
      },
    }}
  />
);
export default CommentText;
