import React, { FC } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  styled,
} from '@mui/material';
import theme, { mediaQuery } from '../../../theme';
import { useConfirm } from 'material-ui-confirm';

type ButtonType = 'success' | 'error';

// TODO: - will be removed after API integration
const rows = [
  {
    fileName: 'dermatology.csv',
    createdDate: '2/10/2022',
    updatedDate: '3/10/2022',
  },
  {
    fileName: 'neurology.csv',
    createdDate: '3/10/2022',
    updatedDate: '4/10/2022',
  },
  {
    fileName: 'pathology.csv',
    createdDate: '4/10/2022',
    updatedDate: '5/10/2022',
  },
  {
    fileName: 'urology.csv',
    createdDate: '6/10/2022',
    updatedDate: '8/10/2022',
  },
  {
    fileName: 'psychiatry.csv',
    createdDate: '9/10/2022',
    updatedDate: '12/10/2022',
  },
];

const TableHeaderCell = styled(TableCell)(() => ({
  textAlign: 'center',
  ...mediaQuery('fontSize', [18, 28]),
}));

const Cell = styled(TableCell)(() => ({
  ...mediaQuery('fontSize', [15, 24]),
}));

const ActionButton: FC<{
  color?: ButtonType;
  onClick?: () => void;
  children?: React.ReactNode;
}> = ({ color, onClick, children }) => (
  <Button variant="contained" color={color} onClick={onClick} fullWidth>
    {children}
  </Button>
);

const PaginationContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 20,
  marginBottom: 10,
});

const FilesTable: FC = () => {
  const confirm = useConfirm();

  const handleDelete = () => {
    confirm({ description: 'This will permanently delete the file.' })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .then(() => {})
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableHeaderCell>File Name</TableHeaderCell>
            <TableHeaderCell>Created Date</TableHeaderCell>
            <TableHeaderCell>Updated Date</TableHeaderCell>
            <TableHeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ fileName, createdDate, updatedDate }) => (
            <TableRow
              key={fileName}
              sx={{
                '&:nth-child(odd)': { backgroundColor: theme.lightGray },
              }}
            >
              <Cell align="center" component="th" scope="row">
                {fileName}
              </Cell>
              <Cell align="center">{createdDate}</Cell>
              <Cell align="center">{updatedDate}</Cell>
              <Cell padding="none" align="center" style={{ width: 420 }}>
                {
                  <>
                    <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                      <InputLabel id="options">Options</InputLabel>
                      <Select
                        labelId="options"
                        id="options"
                        value=""
                        label="Options"
                      >
                        <MenuItem value={10}>
                          <ActionButton color="error" onClick={handleDelete}>
                            Delete
                          </ActionButton>
                        </MenuItem>
                        <MenuItem value={20}>
                          <ActionButton>Edit</ActionButton>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </>
                }
              </Cell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <PaginationContainer>
        <Pagination count={10} showFirstButton showLastButton />
      </PaginationContainer>
    </TableContainer>
  );
};

export default FilesTable;
