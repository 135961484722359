import React, { createContext, useContext, useState } from 'react';

type UserContextProviderProp = {
  children: React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UserContext = createContext<any>(null);

const UserContextProvider = ({ children }: UserContextProviderProp) => {
  const [openUserModal, setOpenUserModal] = useState(false);

  return (
    <UserContext.Provider
      value={{
        openUserModal,
        setOpenUserModal,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;

export const useUsers = () => {
  return useContext(UserContext);
};
