import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  FormControl,
  Grid,
} from '@mui/material';
import Select from '@mui/material/Select';
import FieldTitle from '../../../components/FieldTitle';
import { Formik } from 'formik';
import { useGetUserRoles, UserGroups } from '../../../api/users';
import * as Yup from 'yup';
import { UserResponse, updateUser } from '../../../api/users';
import { useSnackbar } from 'notistack';
import { dashRemover } from '../../../util/helpers';

interface UserEditFormModalProps {
  data: UserResponse;
  open: boolean;
  onClose: () => void;
}

const schema = Yup.object().shape({
  name: Yup.string().max(255).required('Name is required'),
});

const UserEditFormModal: FC<UserEditFormModalProps> = ({
  open,
  onClose,
  data,
}) => {
  const [userGroups, setUserGroups] = useState<UserGroups[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    onClose();
  };
  interface Values {
    email: string;
    name: string;
    role: string;
  }

  const [{}, executeUserRoles] = useGetUserRoles();
  const [{}, executeEdit] = updateUser(data?.id);

  const fetchUserRoles = async () => {
    try {
      const { data } = await executeUserRoles();

      if (data) {
        setUserGroups(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchUserRoles();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>Update user</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            email: data?.email,
            name: data?.name,
            role: data?.role,
          }}
          validationSchema={schema}
          onSubmit={async (values: Values) => {
            try {
              const { data } = await executeEdit({
                data: { name: values.name, role: values.role },
              });
              if (!data) {
                enqueueSnackbar('Something went wrong.', {
                  variant: 'error',
                });
              }
              enqueueSnackbar('User details successfully updated', {
                variant: 'success',
              });
              handleClose();
            } catch (error) {
              enqueueSnackbar('Something went wrong.', {
                variant: 'error',
              });
            }
          }}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={3}
                direction="column"
                sx={{ minWidth: '600px' }}
              >
                <Grid item xs={12}>
                  <FieldTitle>Email</FieldTitle>
                  <TextField
                    required
                    id="email"
                    label="Email"
                    size="small"
                    fullWidth
                    type="email"
                    onChange={handleChange}
                    disabled
                    value={data?.email}
                    onBlur={handleBlur}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldTitle>Name</FieldTitle>
                  <TextField
                    required
                    id="name"
                    label="Name"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                    value={values.name}
                    onBlur={handleBlur}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldTitle>Role</FieldTitle>
                  <FormControl
                    sx={{ width: '100%', minWidth: 200 }}
                    size="small"
                  >
                    <InputLabel id="role">Role</InputLabel>
                    <Select
                      labelId="label-role"
                      id="role"
                      name="role"
                      label="Age"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.role}
                      error={Boolean(touched.role && errors.role)}
                    >
                      {userGroups?.map((userRole: UserGroups) => (
                        <MenuItem key={userRole.id} value={userRole.name}>
                          {dashRemover(userRole.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <DialogActions>
                <Button type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit"> Update </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default UserEditFormModal;
