import React, { FC, useState } from 'react';
import {
  Container,
  Typography,
  styled,
  Button,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import User from '../../components/User';
import Search from './Search';
import { UserRoles } from '../../types/users';
import { mediaQuery } from '../../theme';
import DehazeIcon from '@mui/icons-material/Dehaze';
import Sidebar from './sidebar/SideBarDrawer';
import { hideFileUploadFeature } from '../../configs/constants';
import { getUser } from '../../api/cacheStorage';
import { useUsers } from '../../context/UserContext';

const AppBarComponent = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  position: 'relative',
  backgroundColor: theme.primaryBlue,
  ...mediaQuery('height', ['250px', '120px']),
}));

const MainWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  ...mediaQuery('justifyContent', ['center', 'space-between', 'space-between']),
  ...mediaQuery('flexDirection', ['column', 'row']),
});

const Logo = styled(Typography)(({ theme }) => ({
  color: theme.white,
  ...mediaQuery('justifyContent', ['center']),
  display: 'flex',
}));

const RightWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const AddUserButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.white,
  color: 'black',
  marginLeft: '25px',
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: 20,
  '&:hover': { backgroundColor: theme.gray },
  ...mediaQuery('marginRight', ['20px', '25px', '100px']),
}));

const LeftWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
});

const MenuIconWrapper = styled('div')({
  ...mediaQuery('marginRight', ['50px', '40px']),
});

const AppBar: FC = () => {
  const navigate = useNavigate();
  const { setOpenUserModal } = useUsers();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const isAdmin = getUser().role === UserRoles.PlatformAdmin;

  return (
    <AppBarComponent>
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <MainWrapper>
          <LeftWrapper>
            {isAdmin && !hideFileUploadFeature && (
              <MenuIconWrapper>
                <IconButton onClick={handleDrawerOpen}>
                  <DehazeIcon />
                </IconButton>
              </MenuIconWrapper>
            )}

            <div
              onClick={() => {
                if (!isAdmin) navigate('/landing');
              }}
            >
              <Logo variant="h3">
                <img src="/nhs-logo.png" alt="" />
              </Logo>
            </div>
          </LeftWrapper>
          <RightWrapper>
            {isAdmin ? (
              <AddUserButton
                variant="contained"
                onClick={() => setOpenUserModal(true)}
              >
                Add new user
              </AddUserButton>
            ) : (
              <Search />
            )}

            <User />
          </RightWrapper>
        </MainWrapper>
      </Container>

      {isAdmin && (
        <Sidebar openDrawer={openDrawer} onCloseDrawer={handleDrawerClose} />
      )}
    </AppBarComponent>
  );
};

export default AppBar;
