import { CSSProperties } from 'styled-components';

const breakPointValues = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

const theme = {
  black: '#000000',
  white: '#FFFFFF',
  primaryBlue: '#005EB8',
  lightGray: '#F9FAFB',
  gray: '#BDBDBD',
  error: '#D92D20',
  breakpoints: { values: breakPointValues },
};

export const breakpoints = [
  `${breakPointValues.sm}px`,
  `${breakPointValues.md}px`,
  `${breakPointValues.lg}px`,
  `${breakPointValues.xl}px`,
];

export const mediaQuery = (
  key: keyof CSSProperties,
  values: (string | number | null)[],
) => {
  const result: { [key: string]: unknown } = {};
  values.forEach((item, index) => {
    if (index === 0) {
      result[key] = item;
    } else if (item && breakpoints[index]) {
      result[`@media (min-width: ${breakpoints[index]})`] = { [key]: item };
    }
  });
  return result;
};

export type ThemeType = typeof theme;
export default theme;
