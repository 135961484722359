import React, { FC, useState, MouseEvent } from 'react';
import { Avatar, Typography, styled } from '@mui/material';
import theme from '../theme';
import UserMenu from './UserMenu';
import { getUser } from '../api/cacheStorage';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { logOut } from '../api/cacheStorage';

const Username = styled(Typography)({
  fontSize: 23,
  color: theme.white,
});

const UserContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const User: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    logOut();
    location.reload();
  };

  const userDetail = getUser();
  return (
    <UserContainer>
      <Username mr={1}>{userDetail?.name.toUpperCase()}</Username>
      <Button onClick={handleClick}>
        <Avatar>{userDetail?.name?.[0].toUpperCase()}</Avatar>
        <UserMenu />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleLogOut}>Logout</MenuItem>
      </Menu>
    </UserContainer>
  );
};

export default User;
