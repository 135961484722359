import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  FormControl,
  Grid,
  FormHelperText,
} from '@mui/material';
import Select from '@mui/material/Select';
import { Nullable } from '../../../types/types';
import { User } from '../../../types/users';
import FieldTitle from '../../../components/FieldTitle';
import { Formik } from 'formik';
import { useRegister, useGetUserRoles, UserGroups } from '../../../api/users';
import { useSnackbar } from 'notistack';
import { authValidationMessage } from '../../../configs/responseMessage';
import * as Yup from 'yup';
import { getApiError } from '../../../util/apiService';
import { dashRemover } from '../../../util/helpers';
interface UserFormModalProps {
  open: boolean;
  initialValues: Nullable<User>;
  onClose: () => void;
  handleNextPage: () => void;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .email(authValidationMessage.EMAIL_INVALID)
    .max(255)
    .required(authValidationMessage.EMAIL_REQUIRED),
  password: Yup.string()
    .max(35)
    .required(authValidationMessage.PASSWORD_REQUIRED),
  name: Yup.string().max(255).required('Name is required'),
  role: Yup.string().required('You must select a role'),
});

const UserFormModal: FC<UserFormModalProps> = ({
  open,
  initialValues,
  onClose,
  handleNextPage,
}) => {
  const [userGroups, setUserGroups] = useState<UserGroups[]>([]);

  interface Values {
    email: string;
    name: string;
    role: string;
    password: string;
  }

  const [{}, executePut] = useRegister();
  const [{}, executeUserRoles] = useGetUserRoles();
  const { enqueueSnackbar } = useSnackbar();

  const fetchUserRoles = async () => {
    try {
      const { data } = await executeUserRoles();

      if (data) {
        setUserGroups(data);
      }
    } catch (error) {
      // enqueueSnackbar('Something went wrong.', {
      //   variant: 'error',
      // });
    }
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    fetchUserRoles();
  }, []);

  const handleSubmitUser = () => {
    handleNextPage();
    enqueueSnackbar('User successfully added.', {
      variant: 'success',
    });
  };

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        role: '',
        password: '',
      }}
      validationSchema={schema}
      onSubmit={async (values: Values) => {
        try {
          await executePut({
            data: {
              email: values.email,
              name: values.name,
              role: values.role,
              password: values.password,
            },
          });

          handleSubmitUser();
          handleClose();
        } catch (error) {
          const errorMessage = getApiError(error);
          enqueueSnackbar(errorMessage, {
            variant: 'error',
          });
        }
      }}
    >
      {({
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
      }) => (
        <Dialog open={open} onClose={handleClose} maxWidth="md">
          <form noValidate onSubmit={handleSubmit}>
            <DialogTitle>
              {initialValues ? 'Update user' : 'Add new user'}
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={3}
                direction="column"
                sx={{ minWidth: '600px' }}
              >
                <Grid item xs={12}>
                  <FieldTitle>Email</FieldTitle>
                  <TextField
                    required
                    id="email"
                    label="Email"
                    size="small"
                    fullWidth
                    type="email"
                    inputProps={{
                      autocomplete: 'email',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                    onChange={handleChange}
                    value={values.email}
                    onBlur={handleBlur}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldTitle>Password</FieldTitle>
                  <TextField
                    required
                    id="password"
                    label="Password"
                    size="small"
                    fullWidth
                    type="password"
                    onChange={handleChange}
                    value={values.password}
                    onBlur={handleBlur}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FieldTitle>Name</FieldTitle>
                  <TextField
                    required
                    id="name"
                    label="Name"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                    value={values.name}
                    onBlur={handleBlur}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldTitle>Role</FieldTitle>
                  <FormControl
                    sx={{ width: '100%', minWidth: 200 }}
                    size="small"
                  >
                    <InputLabel id="role">Role*</InputLabel>
                    <Select
                      labelId="label-role"
                      id="role"
                      name="role"
                      label="Age"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.role}
                      error={Boolean(touched.role && errors.role)}
                    >
                      {userGroups?.map((userRole: UserGroups) => (
                        <MenuItem key={userRole.id} value={userRole.name}>
                          {dashRemover(userRole.name)}
                        </MenuItem>
                      ))}
                    </Select>
                    {Boolean(touched.role && errors.role) && (
                      <FormHelperText error id="role">
                        {errors.role}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button type="button" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit">{initialValues ? 'Update' : 'Add'}</Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};

export default UserFormModal;
