import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import LoginPage from './containers/auth/LoginPage';
import LandingPage from './containers/landing/LandingPage';
import HomePage from './containers/home/Home';
import Layout from './containers/layout/Layout';
import SettingsPage from './containers/settings/ SettingsPage';
import AdminPage from './containers/admin/users/AdminPage';
import PatientViewPage from './containers/patient-details/PatientViewPage';
import UploadView from './containers/admin/file-upload/UploadView';
import { hideFileUploadFeature } from './configs/constants';
import { getToken, getUser } from './api/cacheStorage';
import { setupAxiosToken } from './util/apiService';
import { UserRoles } from './types/users';
interface RequireAuthProps {
  comp: JSX.Element;
}

const RequireAuth = ({ comp }: RequireAuthProps) => {
  const token = getToken();
  const location = useLocation();

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    setupAxiosToken(token);
  }

  return comp;
};

export default function NavigationRoutes() {
  const user = getUser();
  const isAdmin = user?.role === UserRoles.PlatformAdmin;
  if (!user) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </BrowserRouter>
    );
  }
  if (isAdmin) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RequireAuth comp={<Layout />} />}>
            <Route path="/" element={<Navigate to={'/login'} />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="admin" element={<AdminPage />} />
            <Route
              path="patient-view/:pathwayId"
              element={<PatientViewPage />}
            />
            {!hideFileUploadFeature && (
              <Route path="admin/file-upload" element={<UploadView />} />
            )}
          </Route>
          <Route path="*" element={<Navigate to="/admin" replace />} />
        </Routes>
      </BrowserRouter>
    );
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RequireAuth comp={<Layout />} />}>
          <Route path="/" element={<Navigate to={'/login'} />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="landing" element={<LandingPage />} />
          <Route path="patient-view/:pathwayId" element={<PatientViewPage />} />
          {!hideFileUploadFeature && (
            <Route path="admin/file-upload" element={<UploadView />} />
          )}
        </Route>
        <Route path="*" element={<Navigate to="/landing" replace />} />
      </Routes>
    </BrowserRouter>
  );
}
