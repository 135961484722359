import React, { FC } from 'react';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import theme from '../../theme';

const ValidationSummeryChart: FC<{ data: object[]; angle?: number }> = ({
  data,
  angle,
}) => (
  <ResponsiveContainer width="100%" height={300}>
    <BarChart
      data={data}
      margin={{
        top: 5,
        bottom: 5,
      }}
    >
      <XAxis dataKey="name" angle={angle} style={{ fontSize: 12 }} />
      <Tooltip />
      <Bar
        dataKey="validations"
        fill={theme.primaryBlue}
        barSize={30}
        radius={[5, 5, 0, 0]}
      />
    </BarChart>
  </ResponsiveContainer>
);
export default ValidationSummeryChart;
