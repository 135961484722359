import React, { FC, useState, KeyboardEvent, HTMLAttributes } from 'react';
import {
  styled,
  Autocomplete,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme, { mediaQuery } from '../../theme';
import { usePathwaySearch, PathwayIdsResponse } from '../../api/patients';
import { useSnackbar } from 'notistack';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const SearchComponent = styled('div')({
  marginRight: 10,
  marginTop: 15,
});

const SearchContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  ...mediaQuery('flexDirection', ['column', 'row']),
});

const StyledSearchButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.white,
  color: 'black',
  marginLeft: '25px',
  textTransform: 'none',
  fontWeight: 'bold',
  padding: '2px 20px',
  '&:hover': { backgroundColor: theme.gray },
  ...mediaQuery('marginRight', ['50px', null, '100px']),
  ...mediaQuery('marginTop', ['10px', null, '0px']),
  ...mediaQuery('fontSize', [15, 20]),
}));

const RadioButton = styled(Radio)(({ theme }) => ({
  color: theme.white,
  '&.Mui-checked': {
    color: theme.white,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 35,
  },

  '@media (max-width: 375px)': {
    '& .MuiSvgIcon-root': {
      fontSize: 25,
    },
  },
  '@media (max-width: 670px)': {
    '& .MuiSvgIcon-root': {
      fontSize: 25,
    },
  },
}));

const StyledAutoComplete = styled(Autocomplete)(({ theme }) => ({
  backgroundColor: theme.white,
  borderRadius: 3,
  ...mediaQuery('minWidth', [200, 350]),
}));

const StyledRadioButtonLabel = styled(Typography)({
  ...mediaQuery('fontSize', [15, 20]),
  ...mediaQuery('marginTop', [2, 0]),
});

const RadioButtonLabel: FC<{
  label: string;
  value: string;
  disabled?: boolean;
}> = ({ label, value, disabled }) => (
  <FormControlLabel
    value={value}
    control={<RadioButton />}
    label={<StyledRadioButtonLabel>{label}</StyledRadioButtonLabel>}
    style={{
      color: theme.white,
    }}
    disabled={disabled}
  />
);

const Search: FC = () => {
  const [{}, execute] = usePathwaySearch();
  const [pathwayIds, setPathwayIds] = useState<PathwayIdsResponse[]>([]);
  const [selectedId, setSelectedId] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();

  const searchPathwayIds = async (searchText: string) => {
    try {
      if (searchText) {
        const { data: pathwayIds } = await execute({
          params: {
            pathwayId: searchText,
          },
        });

        setPathwayIds(pathwayIds);
      }
    } catch (error) {}
  };

  const navigate = useNavigate();

  const handleSearch = async () => {
    if (pathwayIds.find((option) => option.RTT_PATHWAY_ID === selectedId)) {
      navigate(`/patient-view/${selectedId}`);
      location.reload();
    } else {
      enqueueSnackbar('Invalid pathway Id.', {
        variant: 'error',
        style: { marginTop: 120 },
      });
    }
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
      <SearchComponent>
        <SearchContainer>
          <StyledAutoComplete
            freeSolo
            disableClearable
            options={pathwayIds}
            onInputChange={(event, newInputValue: string) => {
              setSelectedId(newInputValue);
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getOptionLabel={(option: any) => option.RTT_PATHWAY_ID || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder="Enter RTT pathway ID"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
                onChange={(e) => searchPathwayIds(e.target.value)}
                onKeyDown={onKeyDown}
              />
            )}
            renderOption={(
              props: HTMLAttributes<HTMLLIElement>,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              option: any,
              { inputValue },
            ) => {
              const matches = match(option.RTT_PATHWAY_ID, inputValue, {
                insideWords: true,
              });
              const parts = parse(option.RTT_PATHWAY_ID, matches);

              return (
                <li {...props}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
          />
          <StyledSearchButton onClick={handleSearch}>Search</StyledSearchButton>
        </SearchContainer>
        <RadioGroup row name="row-radio-buttons-group" defaultValue="ytd">
          <RadioButtonLabel label="Weekly" value="weekly" disabled />
          <RadioButtonLabel label="Monthly" value="monthly" disabled />
          <RadioButtonLabel label="YTD" value="ytd" />
        </RadioGroup>
      </SearchComponent>
    </>
  );
};
export default Search;
